export const Arrays = (function () {
  // #操作符暂时还在stage3
  let _options = {
    // 配置项默认值
    children: "children", // 子选项的key值
    direction: 'in', // 回调调用的方向，in自外向内，out自内向外
    depth: "", // 预留了遍历深度的配置项
  }
  return class Arrays {
    constructor(options = {}) {
      _options = {
        ..._options,
        ...options
      };
    };
    forEach(arr, cb) {
      // 级联数组迭代器
      // TODO 考虑支持异步函数作为回调
      // 简单的实现先用forEach来控制循环
      // 但是要做成可退出的forEach
      (function _forEach(arr, cb, level = 0) {
        arr.forEach((item, index, array) => {
          if (_options.direction == 'in') {
            cb(item, index, array, { level });
            if (item[_options.children] && item[_options.children].length) {
              _forEach(item[_options.children], cb, level + 1);
            }
          } else if (_options.direction == 'out') {
            if (item[_options.children] && item[_options.children].length) {
              _forEach(item[_options.children], cb, level + 1);
            }
            cb(item, index, array, { level });
          }
        });
      }(arr, cb));
    }
    map(arr, cb) {
      return arr.map((item, index, array) => {
        if (_options.direction == 'in') {
          cb(item, index, array);
          if (item[_options.children] && item[_options.children].length) {
            this.map(item[_options.children], cb);
          }
        } else if (_options.direction == 'out') {
          if (item[_options.children] && item[_options.children].length) {
            this.map(item[_options.children], cb);
          }
          cb(item, index, array);
        }
        return item;
      });
    }
    find(arr, cb) {
      for (let i = 0; i < arr.length; i++) {
        const ele = arr[i];
        if (cb(ele, i, arr)) {
          return ele;
        }
        if (ele[_options.children] && ele[_options.children].length) {
          let res = this.find(ele[_options.children], cb);
          if (res) {
            return res;
          }
        }
      };
      return null;
    }
    findAll(arr, cb) {
      let res = [];
      for (let i = 0; i < arr.length; i++) {
        const ele = arr[i];
        if (cb(ele, i, arr)) {
          res.push(ele);
        }
        if (ele[_options.children] && ele[_options.children].length) {
          res = [...res, ...this.findAll(ele[_options.children], cb)];
        }
      };
      return res;
    }
    findIndex() {

    }
    filter(arr, cb) {
      let res = [];
      this.forEach(arr, (item, index, array, { level }) => {
        if (cb(item, index, array, { level })) {
          res.push(item);
        }
      })
      return res;
    }
    reduce() {

    }
    some() {

    }
    every() {

    }
    includes() {

    }
  };
}())

export const traverse = new Arrays();